import axios from '@/libs/axios'

export function reqGetTransactions(payload) {
  return axios.get('api/admin/transactions', { params: payload })
}
export function reqGetTransaction(id) {
  return axios.get(`api/admin/transactions/${id}`)
}
export function reqCreateTransaction(payload) {
  return axios.post('api/admin/transactions', payload)
}
export function reqUpdateTransaction(id, payload) {
  return axios.patch(`api/admin/transactions/${id}`, payload)
}
export function reqDeleteTransaction(id) {
  return axios.delete(`api/admin/transactions/${id}`)
}
